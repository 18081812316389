import _ from "lodash";

const initState = {
    tableData: [],
    tableFields: [],
    forwarder: null,
    recipientIds: []
};

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
    SET_TABLE_DATA(state:any, payload:any) {
        state.tableData = payload;
    },
    SET_TABLE_FIELDS(state:any, payload:any) {
        state.tableFields = payload;
    },
    SET_FORWARDER(state:any, payload:any) {
        state.forwarder = payload;
    },
    SET_RECIPIENT_IDS(state:any, payload:any) {
        state.recipientIds = payload;
    }
};

export const actions = {
    setTableData({ commit }: any, payload: any) {
        commit('SET_TABLE_DATA', payload);
    },
    setTableFields({ commit }:any, payload: any) {
        commit('SET_TABLE_FIELDS', payload);
    },
    setForwarder({ commit }:any, payload: any) {
        commit('SET_FORWARDER', payload);
    },
    setRecipientIds({ commit }:any, payload:any) {
        commit('SET_RECIPIENT_IDS', payload);
    }
};

export const getters = {
    getTableData(state:any) {
        return state.tableData;
    },
    getTableFields(state:any) {
        return state.tableFields;
    },
    getForwarder(state:any) {
        return state.forwarder;
    },
    getRecipientIds(state:any) {
        return state.recipientIds;
    },
    objectView(state:any) {
        return state;
    }
};
